import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { HeroBlock, Space, SEO } from '../components/shared'

import { CollectionSlider, ProductSlider } from '../components/shared/sliders'
import { ArrowDown } from '../components/shared/icons'
import {
  mobileVw,
  desktopVw,
  fonts,
  colors,
  desktopBreakpoint,
} from '../styles'
import { useLocale } from '../hooks'

const Gifting = ({
  location,
  data: { contentfulPageGifting },
  pageContext: { shopenful },
}) => {
  const {
    heroblock,
    decoration,
    decorationDesktop,
    productCollections,
    productSlider,
    descriptionBackground,
    seoDescription,
    seoTitle,
  } = contentfulPageGifting

  const [menu, setMenu] = useState(0)
  const refContainer = useRef(null)

  const [filterMenu, setFilterMenu] = useState(false)

  const focusMenu = () => {
    {
      menu ? refContainer.current.focus() : null
    }
    viewDetails()
  }

  const viewDetails = () => {
    {
      menu
        ? window.scrollTo({
            top: refContainer.current.offsetTop - 100,
            behavior: 'smooth',
          })
        : null
    }
  }

  const handleClose = (i) => {
    setMenu(i)
    setFilterMenu(false)
  }

  useEffect(() => {
    focusMenu()
  }, [menu])
  const locale = useLocale()
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'
    script.async = true
    if (locale === 'fr') {
      setTimeout(() => document.body.appendChild(script), 1000)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])
  return (
    <StyledGiftingSection>
      <SEO
        description={seoDescription}
        location={location}
        title={seoTitle}
        pageType='Gifting'
      />
      <HeroBlock
        data={{
          ...heroblock,
          descriptionBackground,
          marginDescription: ['72'],
          marginBottom: ['0', '0'],
        }}
        lineBreak
        gold
        marginTitle={[50]}
      />
      <Space l={4} s={2} />
      <Relative>
        {productSlider && (
          <ProductSlider
            button='Plus de produits'
            title={productSlider.title}
            products={productSlider.products}
            buttonSlug='/e-boutique/cadeaux'
            shopenful={shopenful}
            gifting={true}
          />
        )}
      </Relative>
      <StyledButton
        onClick={() => setFilterMenu(!filterMenu)}
        className='mobileOnly'
      >
        Menu
        <StyledArrow />
      </StyledButton>
      <Menu display={!!filterMenu}>
        {productCollections &&
          productCollections.map(({ title }, i) => (
            <CategoryMenu
              selected={i === menu}
              onClick={() => handleClose(i)}
              key={i}
            >
              <h2>{title}</h2>
            </CategoryMenu>
          ))}
      </Menu>
      {productCollections &&
        productCollections.map((collection, i) => (
          <CollectionSlider
            key={i}
            menu={menu}
            index={i}
            refContainer={refContainer}
            titleHeading={collection.title}
            slides={collection.slides}
          />
        ))}
      <Image
        className='mobileOnly'
        fluid={decoration.image.fluid}
        alt={decoration.image.title}
      />
      <Image
        className='desktopOnly'
        fluid={decorationDesktop.image.fluid}
        alt={decorationDesktop.image.title}
      />
    </StyledGiftingSection>
  )
}

const StyledGiftingSection = styled.div`
  overflow-x: hidden;
`

const Menu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['display'].includes(prop),
})`
  padding-left: ${mobileVw(16)};
  display: ${(props) => (props.display ? `block` : `none`)};
  position: relative;
  z-index: 1;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin-top: ${desktopVw(0)};
    padding-left: ${desktopVw(130)};
  }
`

const StyledArrow = styled(ArrowDown)`
  width: ${mobileVw(8)};
  height: auto;
  stroke: ${colors.black};
  margin-left: ${mobileVw(5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(8)};
    margin-left: ${desktopVw(15)};
    margin-bottom: ${desktopVw(2)};
  }
`

const StyledButton = styled.button`
  margin-top: ${mobileVw(10)};
  margin-left: ${mobileVw(16)};
  width: ${mobileVw(288)};
  background-color: ${colors.mediumGrey};
  padding: ${mobileVw(22)} 0;
  text-transform: uppercase;
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
  }
`

const Relative = styled.div`
  position: relative;
  z-index: 10;
`

const CategoryMenu = styled.div`
  cursor: pointer;
  display: inline-block;
  margin: ${mobileVw(5)} ${mobileVw(15)} 0 0;
  letter-spacing: ${mobileVw(2.2)};
  padding: ${mobileVw(4)};
  line-height: ${mobileVw(20)};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(2.2)};
  border-radius: ${mobileVw(10)};
  background-color: ${(props) =>
    props.selected ? `${colors.mailleGold}` : `inherit`};
  color: ${(props) => (props.selected ? `${colors.white}` : `${colors.black}`)};

  h2 {
    font-size: ${mobileVw(18)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-right: ${desktopVw(24)};
    margin-bottom: 0;
    line-height: ${desktopVw(24)};
    letter-spacing: ${desktopVw(2.2)};
    background-color: inherit;
    padding: 0;
    border-radius: 0;
    color: ${(props) =>
      props.selected ? `${colors.mailleGold}` : `${colors.black}`};
    border-bottom: ${(props) =>
      props.selected
        ? `solid ${desktopVw(1.5)} ${colors.mailleGold}`
        : `solid ${desktopVw(1.5)} ${colors.white}`};

    h2 {
      font-size: ${desktopVw(22)};
    }
  }
`

export const gifting = graphql`
  query Gifting($id: String!, $node_locale: String!) {
    contentfulPageGifting(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      seoDescription
      seoTitle
      id
      slug
      descriptionBackground {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      heroblock {
        title
        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        video {
          name
          poster {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          videoMov {
            file {
              url
            }
          }
          videoMp4 {
            file {
              url
            }
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      decoration {
        image {
          fluid(maxWidth: 2400) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      decorationDesktop {
        image {
          fluid(maxWidth: 2400) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      productSlider {
        button
        title
        products {
          images {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          shopifyProduct {
            id
            handle
            variants {
              edges {
                node {
                  id
                  title
                  price{
                    amount
                  }
                }
              }
            }
          }
          thumbnailImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title
          slug
          thumbnailLabel
          promotion
        }
      }
      productCollections {
        title
        name
        slides {
          ... on ContentfulPageRecipeCollection {
            tags
            title
            slug
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulPageEBoutiqueCollection {
            slug
            title
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default Gifting
